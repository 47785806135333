exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".App_Grid__3K1O_ {\n  display: grid;\n  grid-gap: 1px;\n  grid-template-columns: auto auto auto auto auto;\n  grid-template-rows: auto auto auto;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,\n    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n  font-weight: 400;\n\n}\n\n@media only screen and (max-device-width: 480px) {\n  .App_Grid__3K1O_ {\n    display: block;\n    font-size: 16px;\n  }\n}\n\n@media only screen and (max-device-width: 400px) {\n  .App_Grid__3K1O_ {\n    display: block;\n    font-size: 13px;\n    font-weight: 300;\n  }\n}\n\n.App_icon__3WOLN {\n  height: 7em;\n  margin-top: 2em;\n  opacity: 0.8;\n}\n\n\n\n.App_Child__1DrVd {\n  min-height: 600px;\n  max-width: 600px;\n  min-width: 90%;\n/*\n  background: linear-gradient(\n      16deg,\n      rgb(11, 30, 56) 0%,\n      rgb(10, 37, 73) 42%,\n      rgb(8, 45, 95) 100%\n    ),\n    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));\n*/\n\n  background-repeat: no-repeat;\n  background-origin: padding-box, border-box;\n  border-radius: 0em;\n  grid-column: 3;\n  grid-row: 2;\n  text-align: center;\n  color: white;\n  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);\n}\n\n@media only screen and (max-device-width: 400px) {\n  .App_tab-list-item__2ORzi {\n    min-width: none;\n  }\n}\n\n\n.App_curtain__2mk8- {\n  background-color: rgba(0, 0, 0, 0.466);\n  position: static;\n  height: 99em;\n  width: auto;\n  grid-column-start: 1;\n  grid-column-end: 6;\n  z-index: 2;\n  -webkit-filter: blur(8px);\n          filter: blur(8px);\n  -webkit-backdrop-filter: blur(1.5em);\n  backdrop-filter: blur(1.5em);\n}\n\n\na {\n  color: rgb(119, 157, 201);\n  text-decoration: none;\n}\n", ""]);

// exports
exports.locals = {
	"Grid": "App_Grid__3K1O_",
	"icon": "App_icon__3WOLN",
	"Child": "App_Child__1DrVd",
	"tab-list-item": "App_tab-list-item__2ORzi",
	"curtain": "App_curtain__2mk8-"
};