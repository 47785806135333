exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OutStore_outStore__2UUFb {\n  padding-top: 1em;\n  background-color: rgba(95, 158, 160, 0.034);\n}\n\n.OutStore_outStore__2UUFb p {\n  padding-top: 1em;\n  color: #37a99e;\n}\n\n.OutStore_outStore__2UUFb a {\n  color: white;\n}\n\n.OutStore_outStore__2UUFb button {\n  background-color: rgba(255, 255, 255, 0);\n  color: white;\n  font-size: 1em;\n  border: white solid 0.05em;\n  border-radius: 0.5em;\n  height: 3em;\n  width: 10em;\n  cursor: pointer;\n}\n\n.OutStore_outStore__2UUFb button:hover {\n  background-color: rgba(255, 255, 255, 0.068);\n}\n\n.OutStore_outStore__2UUFb button:focus {\n  outline: none;\n}\n\n\n.OutStore_outStore__2UUFb\nbutton:disabled,\nbutton[disabled]{\n  color: #555555;\n  border: #555555 solid 0.05em;\n}\n\n\n.OutStore_network__1RjRR p {\n  color: rgb(119, 157, 201);\n  font-size: 0.8em;\n}\n\n\n.OutStore_outStore__2UUFb li {\n  color: rgb(119, 157, 201);\n  font-size: 0.8em;\n}\n\n.OutStore_outStore__2UUFb ul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n\n\n\n\n\n.OutStore_stakeIcon__1ea93 {\n  height: 2em;\n  position: relative;\n  top: 0.1em;\n  right: 1em;\n}\n\n.OutStore_inputDiv__1K6ov {\n  padding: 3em 0 3em 0;\n  height: 5em;\n  align-items: center;\n}\n\n.OutStore_input__2L0ck {\n  height: 3em;\n  width: 28em;\n  border-radius: 0.5em;\n  border: none;\n  text-align: center;\n}\n\ninput:focus {\n  outline: none;\n  border: 0.2em solid rgba(23, 60, 139, 0.301);\n}\n\n\n\n.OutStore_stakeButton__3ZchW {\n  background-color: rgba(255, 255, 255, 0);\n  color: white;\n  font-size: 1em;\n  border: white solid 0.05em;\n  border-radius: 0.5em;\n  height: 3em;\n  width: 20em;\n  margin-top: 1em;\n  cursor: pointer;\n}\n\n\n.OutStore_stakeButton__3ZchW:hover {\n  background-color: #2675cf;\n}\n\n.OutStore_stakeButton__3ZchW:focus {\n  outline: none;\n }\n\n .OutStore_stakeButton__3ZchW p {\n   display: inline;\n   position: relative;\n   bottom: 0.5em;\n   color: white;\n }\n", ""]);

// exports
exports.locals = {
	"outStore": "OutStore_outStore__2UUFb",
	"network": "OutStore_network__1RjRR",
	"stakeIcon": "OutStore_stakeIcon__1ea93",
	"inputDiv": "OutStore_inputDiv__1K6ov",
	"input": "OutStore_input__2L0ck",
	"stakeButton": "OutStore_stakeButton__3ZchW"
};