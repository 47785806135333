exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Expert_expert__3vcc8 {\n  padding-top: 1em;\n  background-color: rgba(95, 158, 160, 0.034);\n  height: 24em;\n  border-radius: 0 0 2em 2em;\n}\n\n.Expert_expert__3vcc8 p {\n  padding-top: 1em;\n  color: #37a99e;\n}\n\n.Expert_expert__3vcc8 button {\n  background-color: rgba(255, 255, 255, 0);\n  color: white;\n  font-size: 1em;\n  border: white solid 0.05em;\n  border-radius: 0.5em;\n  height: 5em;\n  width: 15em;\n  cursor: pointer;\n}\n\n.Expert_expert__3vcc8 button:hover {\n  background-color: rgba(255, 255, 255, 0.068);\n}\n\n.Expert_expert__3vcc8 button:focus {\n  outline: none;\n}\n\n\n.Expert_expert__3vcc8\nbutton:disabled,\nbutton[disabled]{\n  color: #555555;\n  border: #555555 solid 0.05em;\n}\n\n\n.Expert_network__3iSAo p {\n  color: rgb(119, 157, 201);\n  font-size: 0.8em;\n}\n\n\n.Expert_expert__3vcc8 li {\n  color: rgb(119, 157, 201);\n  font-size: 0.8em;\n}\n\n.Expert_expert__3vcc8 ul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n", ""]);

// exports
exports.locals = {
	"expert": "Expert_expert__3vcc8",
	"network": "Expert_network__3iSAo"
};