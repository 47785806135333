exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InStore_inStore__2M2Ye {\n  padding-top: 1em;\n  background-color: rgba(95, 158, 160, 0.034);\n}\n\n.InStore_inStore__2M2Ye p {\n  padding-top: 1em;\n  color: #37a99e;\n}\n\n.InStore_inStore__2M2Ye a {\n  color: white;\n}\n\n.InStore_inStore__2M2Ye button {\n  background-color: rgba(255, 255, 255, 0);\n  color: white;\n  font-size: 1em;\n  border: white solid 0.05em;\n  border-radius: 0.5em;\n  height: 3em;\n  width: 10em;\n  cursor: pointer;\n}\n\n.InStore_inStore__2M2Ye button:hover {\n  background-color: rgba(255, 255, 255, 0.068);\n}\n\n.InStore_inStore__2M2Ye button:focus {\n  outline: none;\n}\n\n.InStore_inStore__2M2Ye\nbutton:disabled,\nbutton[disabled]{\n  color: #555555;\n  border: #555555 solid 0.05em;\n}\n\n\n.InStore_network__2MZLy p {\n  color: rgb(119, 157, 201);\n  font-size: 0.8em;\n}\n\n\n.InStore_inStore__2M2Ye li {\n  color: rgb(119, 157, 201);\n  font-size: 0.8em;\n}\n\n.InStore_inStore__2M2Ye ul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n\n\n.InStore_stakeIcon__2fYpc {\n  height: 2em;\n  position: relative;\n  top: 0.1em;\n  right: 1em;\n}\n\n.InStore_inputDiv__1mdyN {\n  padding: 3em 0 3em 0;\n  height: 5em;\n  align-items: center;\n}\n\n.InStore_input__1GQDi {\n  height: 3em;\n  width: 28em;\n  border-radius: 0.5em;\n  border: none;\n  text-align: center;\n}\n\ninput:focus {\n  outline: none;\n  border: 0.2em solid rgba(23, 60, 139, 0.301);\n}\n\n\n\n.InStore_stakeButton__1DirX {\n  background-color: rgba(255, 255, 255, 0);\n  color: white;\n  font-size: 1em;\n  border: white solid 0.05em;\n  border-radius: 0.5em;\n  height: 3em;\n  width: 20em;\n  margin-top: 1em;\n  cursor: pointer;\n}\n\n\n.InStore_stakeButton__1DirX:hover {\n  background-color: #2675cf;\n}\n\n.InStore_stakeButton__1DirX:focus {\n  outline: none;\n }\n\n .InStore_stakeButton__1DirX p {\n   display: inline;\n   position: relative;\n   bottom: 0.5em;\n   color: white;\n }\n", ""]);

// exports
exports.locals = {
	"inStore": "InStore_inStore__2M2Ye",
	"network": "InStore_network__2MZLy",
	"stakeIcon": "InStore_stakeIcon__2fYpc",
	"inputDiv": "InStore_inputDiv__1mdyN",
	"input": "InStore_input__1GQDi",
	"stakeButton": "InStore_stakeButton__1DirX"
};