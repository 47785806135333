exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Navigation_navigation__2jP6- {\n  grid-column: 3;\n  grid-row: 2;\n  position: absolute;\n  margin-top: -2em;\n  margin-left: 2em;\n\n}\n\n.Navigation_buttonActive__2mytU {\n  padding: 0.4em;\n  font-weight: 600;\n  color: rgb(58, 58, 58);\n  background-color: #ffffff;\n  border: none;\n  width: 13em;\n  height: 2em;\n  border-radius: 3em 3em 0 0;\n  margin-right: -1.5em;\n  border: 0.3em solid rgba(8, 67, 134, 0.199);\n  border-bottom: none;\n  z-index: 1;\n  position: relative;\n  box-shadow: -10px 0px 13px 1px #00000031, 10px -10px 13px 5px #0000001f,\n    0px -30px 50px 13px rgba(0, 0, 0, 0);\n  cursor: pointer;\n}\n.Navigation_buttonNonActive__1o6HK {\n  padding: 0.5em;\n  font-weight: 600;\n  color: rgba(247, 247, 247, 0.726);\n  background-color: #5781cfc0;\n  border: none;\n  width: 11.5em;\n  height: 2em;\n  border-radius: 3em 3em 0 0;\n  margin-right: -1.5em;\n  border: 0.2em solid rgba(37, 66, 99, 0.705);\n  border-bottom: none;\n  z-index: 0;\n  position: relative;\n  cursor: pointer;\n}\n\n.Navigation_buttonNonActive__1o6HK:hover {\n  background-color: #5781cfd7;\n  color: rgb(247, 247, 247);\n}\n\n.Navigation_buttonActive__2mytU:focus {\n  outline: none;\n}\n.Navigation_buttonNonActive__1o6HK:focus {\n  outline: none;\n}\n", ""]);

// exports
exports.locals = {
	"navigation": "Navigation_navigation__2jP6-",
	"buttonActive": "Navigation_buttonActive__2mytU",
	"buttonNonActive": "Navigation_buttonNonActive__1o6HK"
};