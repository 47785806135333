exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Meta_Meta__2KSeY h1 {\n  margin-top: 1.2em;\n}\n.Meta_icon__3bQWo {\n  height: 7em;\n  margin-top: 2em;\n  opacity: 0.8;\n}\n\n.Meta_inputDiv__2kNfa {\n  padding: 3em;\n  align-items: center;\n}\n\n.Meta_input__1W144 {\n  height: 3em;\n  width: 20em;\n  border-radius: 0.5em;\n  border: none;\n  text-align: center;\n}\n\ninput:focus {\n  outline: none;\n  border: 0.2em solid rgba(23, 60, 139, 0.301);\n}\n\n.Meta_totals__2rVC3 {\n  margin-top: 3em;\n  height: 3em;\n}\n\n.Meta_goMax__3IfU_ {\n  cursor: pointer;\n}\n\n.Meta_stakeButton__2w4FE {\n  background-color: rgba(255, 255, 255, 0);\n  color: white;\n  font-size: 1em;\n  border: white solid 0.05em;\n  border-radius: 0.5em;\n  height: 3em;\n  width: 10em;\n  margin-top: 1em;\n  cursor: pointer;\n}\n\n.Meta_stakeButton__2w4FE:hover {\n  background-color: #2675cf;\n}\n\n.Meta_unstakeButton__3_1fT {\n  background-color: rgba(255, 255, 255, 0);\n  color: white;\n  font-size: 1em;\n  border: white solid 0.05em;\n  border-radius: 0.5em;\n  height: 3em;\n  width: 8em;\n  margin-top: 1em;\n  cursor: pointer;\n}\n\n.Meta_stakeButton__2w4FE:focus {\n  outline: none;\n }\n\n.Meta_unstakeButton__3_1fT:hover {\n  background-color: #1c375f;\n}\n\n.Meta_unstakeButton__3_1fT:focus {\n outline: none;\n}\n\n.Meta_stakeIcon__1Xtvo {\n  height: 2em;\n  position: relative;\n  top: 0.1em;\n  right: 1em;\n}\n\n.Meta_stakeButton__2w4FE p {\n  display: inline;\n  position: relative;\n  bottom: 0.5em;\n}\n\n.Meta_unstakeButton__3_1fT p {\n  display: inline;\n  position: relative;\n  bottom: 0.5em;\n}\n", ""]);

// exports
exports.locals = {
	"Meta": "Meta_Meta__2KSeY",
	"icon": "Meta_icon__3bQWo",
	"inputDiv": "Meta_inputDiv__2kNfa",
	"input": "Meta_input__1W144",
	"totals": "Meta_totals__2rVC3",
	"goMax": "Meta_goMax__3IfU_",
	"stakeButton": "Meta_stakeButton__2w4FE",
	"unstakeButton": "Meta_unstakeButton__3_1fT",
	"stakeIcon": "Meta_stakeIcon__1Xtvo"
};